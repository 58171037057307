import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import PrintButton from './components/Print';
function App() {
  const [count, setCount] = useState(1)
  const [isSmall, setIsSmall] = useState(true)
  return (
    <div className="App">
      <header>
        <h1>
          Печать этикеток
        </h1>
      </header>
      <p>
        Печать кондитерской этикетки
        <div>
          <CountField value={count} onCount={setCount} /><br />
          <IsSmallField value={isSmall} onChange={setIsSmall} /><br />
          <PrintButton type="pastry" amount={count} small={isSmall} />
        </div>
      </p>
    </div>
  );
}

interface CountFieldProps {
  value: number,
  onCount: (a: number) => void
}

function CountField({ value, onCount }: CountFieldProps) {
  return (
    <>
      <label>Количество:</label><br/>
      <input type="number" value={String(value)} onChange={(e) => { onCount(Number(e.target.value)) }} style={{fontSize: "2em", width: "80%"}} pattern="\d*" />
    </>
  )
}

interface IsSmallFieldProps {
  value: boolean,
  onChange: (a: boolean) => void
}

function IsSmallField({ value, onChange }: IsSmallFieldProps) {
  return (
    <>
      <label>Печать на маленьком:</label>
      <input type="checkbox" defaultChecked={value} onChange={(e) => { onChange(e.target.checked) }} style={{height: "2em", width: "2em"}} />
    </>
  )
}

export default App;

import './Print.css'

interface Props {
    type: string,
    amount: number,
    small: boolean
}

function PrintButton({type, amount, small}:Props) {
    return (
        <button className='PrintButton' onClick={() => {printLabel(type, amount, small)}}>
            Печать
        </button>
    )
}

function printLabel(type: string, amount: number, small: boolean) {
    const data = {
        zpl: `^XA~SD15^CI28^CWN,E:NOT000.TTF^CFN,15,15^LH12,36^FO0,0^FDПродукт:^FS^FO0,48^GB424,1,1,B^FS^FO0,60^FDДата и время изготовления:^FS^FO0,108^GB424,1,1,B^FS^FO0,120^FDСрок годности:^FS^FO0,168^GB424,1,1,B^FS^FO0,180^FDИзготовил:^FS^FO0,228^GB424,1,1,B^FS^PQ${amount},0,1,Y^XZ`,
        small: small
    }
    const res = fetch(`https://prod-04.westeurope.logic.azure.com:443/workflows/7b087bbc40aa4c62a5f62f2c6b16c8c5/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=z4RfgZFlGPrb3A7LVA8Ejqy5fyv_-kpu9NUxoqN6qCY`, {
        method: "POST",
        mode: "no-cors",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          }
    })
}

export default PrintButton;